
























































import { defineComponent, ref, computed } from '@vue/composition-api';
import Store from '@/store';

export default defineComponent({
  props: {
    messageLogId: { type: Number, required: true },
    workOrderId: { type: String, required: true },
  },
  setup(props) {
    const dialog = ref(false);

    const isLoading = computed(():boolean => Store.getters['messageLog/getLoading']);

    /**
     * Closes dialog box
     */
    const closeDialog = () => {
      dialog.value = false;
    };

    const resendMessage = async () => {
      await Store.dispatch('messageLog/resendOutboundMessage', {
        id: props.messageLogId,
        workOrderId: props.workOrderId,
      });
      closeDialog();
    };

    return {
      dialog,
      isLoading,
      closeDialog,
      resendMessage,
    };
  },
});
