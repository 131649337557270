
























































import {
  defineComponent, ref, PropType,
} from '@vue/composition-api';
import { IMessageLogOutboundRecord, IMessageLogInboundRecord } from '@/types';

export default defineComponent({
  props: {
    messageData: {
      type: Object as PropType<IMessageLogOutboundRecord>,
      default: () => {},
    },
    inboundData: {
      type: Object as PropType<IMessageLogInboundRecord>,
      default: () => {},
    },
    logType: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dialog = ref(false);

    const jsonPayload = props.logType === 'outbound' ? JSON.parse(props.messageData.payload)
      : JSON.parse(props.inboundData.jsonReceived);

    /**
     * Closes dialog box
     */
    const closeDialog = () => {
      dialog.value = false;
    };

    return {
      dialog,
      jsonPayload,
      closeDialog,
    };
  },
});
