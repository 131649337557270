







































































































































































































































































































import {
  reactive,
  ref,
  defineComponent,
  computed,
} from '@vue/composition-api';
import Store from '@/store';
import { IPagination } from '@/types';
import MessageLogViewDialog from './MessageLogViewDialog.vue';
import MessageLogResendDialog from './MessageLogResendDialog.vue';
import MessageLogRebuildDialog from './MessageLogRebuildDialog.vue';
import { delayCall } from '../../utils';

export default defineComponent({
  components: {
    MessageLogViewDialog,
    MessageLogResendDialog,
    MessageLogRebuildDialog,
  },
  setup() {
    const headers = [
      { text: 'Work Order', align: 'start', value: 'workOrder' },
      {
        text: 'Message Name', align: 'start', value: 'outboundMessage', sortable: false,
      },
      { text: 'Status', value: 'status', sortable: false },
      { text: 'Alarm Type', value: 'action' },
      { text: 'Done At', value: 'createdAt' },
      { text: 'Response Status', value: 'success' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const paginationChanging = ref(false);

    const items = computed(() => Store.getters['messageLog/getOutboundLogs']);
    const isLoading = computed(() => Store.getters['messageLog/getLoading']);
    const pagination = computed(() => Store.getters['messageLog/getOutboundPagination'] as IPagination);
    const openedPanel = ref([]);
    const responseStatusOptions = [
      {
        text: 'Success',
        value: 1,
      },
      {
        text: 'Error',
        value: 0,
      },
    ];
    const alarmTypeOpts = [
      {
        text: 'Field Change',
        value: 'field',
      },
      {
        text: 'Status Change',
        value: 'status',
      },
    ];

    const createdAtPicker = reactive({
      value: [] as string[],
      menu: false,
      dialog: false,
    });

    const filterObject = computed(() => Store.getters['messageLog/getOutboundFilter']);

    const getOutboundData = async () => {
      openedPanel.value = [];
      await Store.dispatch('messageLog/loadOutboundLogs');
    };

    const onFilterChange = () => {
      // delays making the call until .75s after key release
      // and resets that waiting time if another key is pressed
      // this prevents running multiple api calls when typing
      delayCall(() => {
        Store.dispatch('messageLog/setOutboundPaginationPage', 1);
        getOutboundData();
      }, 750);
    };

    const onCreatedAtCancel = () => {
      createdAtPicker.menu = false;
      createdAtPicker.dialog = false;
    };

    const onCreatedAtOk = (triggerSearch = true) => {
      const dates = createdAtPicker.value;
      if (dates.length > 1) {
        Store.dispatch('messageLog/setFilterCreatedAtOutbound', dates);
        if (triggerSearch) getOutboundData();
      }

      createdAtPicker.menu = false;
      createdAtPicker.dialog = false;
    };

    const onCreatedAtClear = (triggerSearch = true) => {
      createdAtPicker.value = [];
      Store.dispatch('messageLog/setFilterCreatedAtOutbound', '');
      if (triggerSearch) getOutboundData();
    };

    /**
     * Gets new sort by value and updates outboundFilter.sortBy in the
     * MessageLog store and calls getOutboundData()
     * @param {string} sortBy Items per page
     */
    const onColSortBy = (sortBy: string) => {
      const sortByValue = sortBy || '';
      Store.dispatch('messageLog/setFilterSortByOutbound', sortByValue);
      getOutboundData();
    };

    /**
     * Gets descendent boolean and updates outboundFilter.isDesc in
     * the MessageLog store and calls getOutboundData()
     * @param {boolean[]} sortBy Items per page
     */
    const onColSortDesc = (isDesc: boolean[]) => {
      const isDescValue = isDesc ? '-' : '';
      Store.dispatch('messageLog/setFilterIsDescOutbound', isDescValue);
      getOutboundData();
    };

    /**
     * Gets the new page and updates the outboundPagination.page in the
     * MessageLog store and calls getOutboundData()
     * @param {number} page New page number
     */
    const onPageChange = async (page: number) => {
      paginationChanging.value = true;
      await Store.dispatch('messageLog/setOutboundPaginationPage', page);
      await getOutboundData();
      paginationChanging.value = false;
    };

    /**
     * Gets the new number of items per page and updates the data
     * pagination.itemsPerPage in the MessageLog and calls getOutboundData()
     * @param {number} itemsPerPage Items per page
     * @return {void}
     */
    const onItemsPerPageChange = async (itemsPerPage: number) => {
      paginationChanging.value = true;
      await Store.dispatch('messageLog/setOutboundPaginationItemsPerPage', itemsPerPage);
      await getOutboundData();
      paginationChanging.value = false;
    };

    getOutboundData();

    return {
      headers,
      items,
      isLoading,
      responseStatusOptions,
      createdAtPicker,
      onFilterChange,
      onCreatedAtCancel,
      onCreatedAtOk,
      onCreatedAtClear,
      filterObject,
      openedPanel,
      pagination,
      onPageChange,
      paginationChanging,
      onItemsPerPageChange,
      alarmTypeOpts,
      onColSortBy,
      onColSortDesc,
    };
  },
});
