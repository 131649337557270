


























































































































































































































































import {
  defineComponent, computed, reactive, ref,
} from '@vue/composition-api';
import Store from '@/store';
import { IMessageLogInboundRecord, IPagination } from '@/types';
import MessageLogViewDialog from './MessageLogViewDialog.vue';
import { delayCall } from '../../utils';

export default defineComponent({
  components: {
    MessageLogViewDialog,
  },
  setup() {
    const headers = [
      { text: 'Work Order', align: 'start', value: 'workOrderIdentifier' },
      { text: 'Status', value: 'statusName' },
      { text: 'Done At', value: 'createdAt' },
      { text: 'Response Status', value: 'responseStatus' },
      { text: 'Status Code', value: 'statusCode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const paginationChanging = ref(false);

    const items = computed(() => Store.getters['messageLog/getInboundLogs'] as IMessageLogInboundRecord[]);
    const isLoading = computed(() => Store.getters['messageLog/getLoading']);
    const pagination = computed(() => Store.getters['messageLog/getPagination'] as IPagination);
    const openedPanel = ref([]);

    const responseStatusOpts = [
      {
        text: 'Success',
        value: '1',
      },
      {
        text: 'Error',
        value: '0',
      },
    ];

    const createdAtPicker = reactive({
      value: [] as string[],
      menu: false,
      dialog: false,
    });

    const filterObject = computed(() => Store.getters['messageLog/getFilter']);

    const getInboundData = async () => {
      openedPanel.value = [];
      await Store.dispatch('messageLog/loadInboundLogs');
    };

    const onFilterChange = () => {
      // delays making the call until .75s after key release
      // and resets that waiting time if another key is pressed
      // this prevents running multiple api calls when typing
      delayCall(() => {
        Store.dispatch('messageLog/setPaginationPage', 1);
        getInboundData();
      }, 750);
    };

    const onCreatedAtCancel = () => {
      createdAtPicker.menu = false;
      createdAtPicker.dialog = false;
    };

    const onCreatedAtOk = (triggerSearch = true) => {
      const dates = createdAtPicker.value;
      if (dates.length > 1) {
        Store.dispatch('messageLog/setFilterCreatedAt', dates);
        if (triggerSearch) getInboundData();
      }

      createdAtPicker.menu = false;
      createdAtPicker.dialog = false;
    };

    const onCreatedAtClear = (triggerSearch = true) => {
      createdAtPicker.value = [];
      Store.dispatch('messageLog/setFilterCreatedAt', '');
      if (triggerSearch) getInboundData();
    };

    /**
     * Gets new sort by value and updates filter.sortBy in the
     * MessageLog and calls getInboundData()
     * @param {string} sortBy Items per page
     */
    const onColSortBy = (sortBy: string) => {
      const sortByValue = sortBy || '';
      Store.dispatch('messageLog/setFilterSortBy', sortByValue);
      getInboundData();
    };

    /**
     * Gets descendent boolean and updates filter.isDesc in
     * the MessageLog and calls getInboundData()
     * @param {boolean[]} sortBy Items per page
     */
    const onColSortDesc = (isDesc: boolean[]) => {
      const isDescValue = isDesc ? '-' : '';
      Store.dispatch('messageLog/setFilterIsDesc', isDescValue);
      getInboundData();
    };

    /**
     * Gets the new page and updates the data pagination.page in the
     * MessageLog and calls getInboundData()
     * @param {number} page New page number
     */
    const onPageChange = async (page: number) => {
      paginationChanging.value = true;
      await Store.dispatch('messageLog/setPaginationPage', page);
      await getInboundData();
      paginationChanging.value = false;
    };

    /**
     * Gets the new number of items per page and updates the data
     * pagination.itemsPerPage in the MessageLog and calls getInboundData()
     * @param {number} itemsPerPage Items per page
     * @return {void}
     */
    const onItemsPerPageChange = async (itemsPerPage: number) => {
      paginationChanging.value = true;
      await Store.dispatch('messageLog/setPaginationItemsPerPage', itemsPerPage);
      await getInboundData();
      paginationChanging.value = false;
    };

    getInboundData();

    return {
      headers,
      items,
      filterObject,
      openedPanel,
      onFilterChange,
      createdAtPicker,
      onCreatedAtCancel,
      onCreatedAtOk,
      onCreatedAtClear,
      isLoading,
      onColSortBy,
      onColSortDesc,
      onPageChange,
      onItemsPerPageChange,
      pagination,
      responseStatusOpts,
      paginationChanging,
    };
  },
});
