























import {
  defineComponent,
} from '@vue/composition-api';
import Store from '@/store';
import router from '@/router';
import OutboundLogsTable from '@/components/MessageLogs/OutboundLogsTable.vue';
import InboundLogsTable from '@/components/MessageLogs/InboundLogsTable.vue';

export default defineComponent({
  components: {
    OutboundLogsTable,
    InboundLogsTable,
  },
  setup() {
    const tab = null;
    const { templateId } = router.app.$route.params;

    const setTemplate = () => {
      Store.dispatch('messageLog/setWorkOrderTemplate', templateId);
    };

    setTemplate();

    return {
      tab,
    };
  },
});
